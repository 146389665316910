<template>
  <div class="bg_F8F8F8">
    <div
      class="news_box mb_20 bg_FFFFFF cursor-pointer"
      v-for="item in newsList"
      :key="item.id"
      @click="handleToDetail(item)"
    >
      <el-row>
        <el-col :span="6">
          <img :src="item.img" class="banner_image" />
        </el-col>
        <el-col :span="15" class="title_padding">
          <div class="title">{{ item.title }}</div>
          <!-- <div v-html="item.content" class="content mt_40"></div> -->
          <div class="content mt_40">{{item.seoDescription}}</div>
        </el-col>
        <el-col :span="3" class="text-center mt">
          <div class="date">{{ item.date }}</div>
          <div class="year">{{ item.year }}</div>
        </el-col>
      </el-row>
    </div>

    <div class="flex justify-center mt_20 mb_30">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="current"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import { getNewsPage } from '@/api/home';
export default {
  props: {
    id: {
      type: Number
    },
    fatherTitle: {
      type: String
    },
    title: {
      type: String
    }
  },
  data() {
    return {
      size: 10,
      current: 1,
      newsList: [],
      info: '',
      total: 0
    };
  },
  mounted() {
    if (this.id) {
      this.getNewsList();
    }
  },
  watch: {
    id() {
      this.getNewsList();
    }
  },
  methods: {
    getNewsList() {
      const obj = {
        id: this.id,
        current: this.current,
        size: this.size
      };
      getNewsPage(obj).then((res) => {
        this.newsList = res.data.records;
        this.total = res.data.total;
        this.newsList.forEach((item) => {
          item.year = item.updateTime.substr(0, 4);
          item.date = item.updateTime.substr(5, 6);
        });
      });
    },
    handleCurrentChange(val) {
      this.current = val;
      this.getNewsList();
    },
    handleSizeChange(val) {
      this.current = 1;
      this.size = val;
      this.getNewsList();
    },
    handleToDetail(tag) {
      this.$router.push({
        path: './newsDetail',
        query: { id: tag.id }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.news_box {
  // width: 1118px;
}
.bg_F8F8F8 {
  background: #f8f8f8;
  height: 100%;
  .banner_image {
    height: 220px;
    width: 100%;
  }
  .date {
    font-size: 22px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #111c2f;
  }
  .year {
    font-size: 22px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #d0d0d0;
  }
  .title_padding {
    padding: 50px 0 0 50px;
    .title {
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;

      font-size: 22px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #111c2f;
    }
    .content {
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;

      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #5b5b5b;
      line-height: 35px;
    }
  }
  .mt {
    margin-top: 80px;
  }
}
</style>